@use 'styles/constants';
@forward 'styles/loader';
@forward 'styles/elements';
@forward 'styles/neumorphism';

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'EB Garamond', serif;
  background: constants.$bg-color;
  color: constants.$text-color;
}

::-webkit-scrollbar {
  display: none;
}
