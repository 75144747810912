.loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & .dot {
    margin: 2px;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #666;
    border-radius: 50%;
    animation: ellipsis 0.6s infinite ease-in-out;
  }

  & .dot:nth-child(1) {
    animation-delay: 0s;
  }

  & .dot:nth-child(2) {
    animation-delay: 0.1s;
  }

  & .dot:nth-child(3) {
    animation-delay: 0.2s;
  }
}

@keyframes ellipsis {
  0%,
  80%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(1.25);
    opacity: 0.8;
  }
}
