@use 'constants';
@use 'neumorphism';

*:focus {
  outline: 1px solid constants.$border-color;
  outline-offset: 1px;
}

button {
  padding: 4px 16px;
  border-radius: 8px;
  @include neumorphism.neumorphic(6, 6, 8);

  &:active:not(:disabled) {
    @include neumorphism.neumorphic(2, 2, 4);
  }

  &:disabled {
    box-shadow: unset;
  }
}

select {
  background-color: constants.$bg-color;
}

textarea {
  background-color: constants.$bg-color;
}

input {
  padding: 4px 16px;
  border-radius: 8px;
  @include neumorphism.neumorphic_inset(6, 6, 8);
  background: constants.$bg-color;

  &:active:not(:disabled) {
    @include neumorphism.neumorphic(2, 2, 4);
  }
}
